export function truncateString(str, len = null) {
  const num = len === null ? 30 : len
  if (str.length > num) {
    return str.slice(0, num) + "..."
  } else {
    return str
  }
}

export const truncateFilename = (str, len) => {
  const ext = str.substring(str.lastIndexOf(".") + 1, str.length).toLowerCase()
  let filename = str.replace("." + ext, "")
  if (filename.length <= len) {
    return str
  }
  filename = filename.substr(0, len) + (str.length > len ? "[...]" : "")
  return filename + "." + ext
}

export function joinArrays(lookupTable, mainTable, lookupKey, mainKey, select) {
  const l = lookupTable.length,
    m = mainTable.length,
    lookupIndex = [],
    output = []
  for (let i = 0; i < l; i++) {
    // loop through l items
    const row = lookupTable[i]
    lookupIndex[row[lookupKey]] = row // create an index for lookup table
  }
  for (let j = 0; j < m; j++) {
    // loop through m items
    const y = mainTable[j]
    const x = lookupIndex[y[mainKey]] // get corresponding row from lookupTable
    output.push(select(y, x)) // select only the columns you need
  }
  return output
}

export function arrayRemove(arr, value) {
  return arr.filter(function (ele) {
    return ele !== value
  })
}

export function stripHtml(html) {
  let doc = new DOMParser().parseFromString(html, "text/html")
  return doc.body.textContent || ""
}

export function normalizeNodeType(nodeType) {
  let t
  switch (nodeType) {
    case "node__article":
      t = "Article"
      break
    case "node__listing":
      t = "Listing"
      break
    case "node__ig_post":
      t = "Instagram"
      break
    case "node__youtube_video":
      t = "Youtube"
      break
    case "node__community":
      t = "Community"
      break
    case "node__directory":
      t = "Directory"
      break
    default:
      t = ""
  }
  return t
}

export const pluralize = (string, num, pluralizer) => {
  return num === 1 ? string : string + pluralizer
}

export const subStrBeforeAfterChars = (str, char, pos) => {
  if (pos === "b") return str.substring(str.indexOf(char) + char.length)
  else if (pos === "a") return str.substring(0, str.indexOf(char))
  else return str
}

export const numberToCurrency = (n, currency) => {
  return currency + n.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
}

export const splitToChunks = (arr, size = null) => {
  const array = [...arr]
  if (size === null) {
    size = array.length >= 3 ? 3 : 1
  }
  let result = []
  for (let i = size; i > 0; i--) {
    result.push(array.splice(0, Math.ceil(array.length / i)))
  }
  return result
}

export const getYoutubeEmbed = (url) => {
  const youtubeRegExp = /(?:[?&]vi?=|\/embed\/|\/\d\d?\/|\/vi?\/|https?:\/\/(?:www\.)?youtu\.be\/)([^&\n?#]+)/
  const match = url.match(youtubeRegExp)

  if (match && match[1].length === 11) {
    return `https://youtube.com/embed/${match[1]}`
  }

  return false
}

export const getYears = (startYear) => {
  const currentYear = new Date().getFullYear()
  let years = []
  startYear = startYear || 1900
  while (startYear <= currentYear + 1) {
    years.push(startYear++)
  }
  return years.reverse()
}

export const searchArray = (element, array) => {
  const len = array.length,
    str = element.toString().toLowerCase()
  for (let i = 0; i < len; i++) {
    if (array[i].toLowerCase() === str) {
      return i
    }
  }
  return -1
}

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  })
}

export const timeToMillis = (time) => new Date("01/01/2000 " + time)

export const generateTimeSeries = (step, startHour = 0, startMinute = 0) => {
  const dt = new Date(1970, 0, 1, startHour, startMinute),
    rc = []
  while (dt.getDate() === 1) {
    const toLocalStr = dt.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    })
    rc.push(toLocalStr.replace(/^0(?:0:0?)?/, ""))
    dt.setMinutes(dt.getMinutes() + step)
  }
  return rc
}

export const emailIsValid = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

export function urlIsValid(str) {
  const regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/
  return regexp.test(str)
}

export const capitalize = (str) => {
  return str.replace(/\b[a-zA-Z]/g, (match) => match.toUpperCase())
}

export const urlToSecure = (url, https) => {
  url = url.trim().toLowerCase()

  if (/^\.*\/|^(?!localhost)\w+:/.test(url)) {
    return url
  }

  return url.replace(
    /^(?!(?:\w+:)?\/\/)/,
    https ? "https://" : "http://"
  )
}
