import React, { useState } from "react"
import "./Progressive-Image.css"

const cloudinaryBase = `https://res.cloudinary.com/${process.env.GATSBY_CLOUDINARY_CLOUD_NAME}`

const omit = (obj, omitKey) =>
  Object.keys(obj).reduce((result, key) => {
    if (key !== omitKey) {
      result[key] = obj[key]
    }
    return result
  }, {})

const addDefaultSrc = (e) => {
  e.target.src = `${cloudinaryBase}/image/upload/defaults/cco_logo`
}

const ProgressiveImage = props => {
  const { overlaySrc, src } = props
  const [loaded, setLoaded] = useState(false)
  let filteredProps = omit(props, "overlaySrc")
  return (
    <div>
      {!loaded && (
        <img
          {...filteredProps}
          src={overlaySrc}
        />
      )}
      <img
        {...filteredProps}
        className={"safari-touch"}
        style={
          !loaded
            ? { ...filteredProps.style, visibility: "hidden" }
            : { ...filteredProps.style }
        }
        onLoad={() => setLoaded(true)}
        onError={addDefaultSrc}
        src={src}
      />
    </div>
  )
}

export default ProgressiveImage