import React, { Component } from "react"
import Dropzone from "./components/Dropzone"
import Progress from "./components/Progress"
import check from "./assets/baseline-check_circle_outline-24px.svg"
import axios from "axios"
import { getUuid } from "../../utils/Auth"
import ImageTools from "./components/ImageTools"
import Grid from "@material-ui/core/Grid"
import { truncateFilename } from "../../helpers/common"
// import { navigate } from "gatsby-link"

import "./Upload.css"

const cloudinaryApiKey = `${process.env.GATSBY_CLOUDINARY_API_KEY}`
const cloudinaryUrl = "https://api.cloudinary.com/v1_1/cco/auto/upload"
const imageTools = new ImageTools()

const styles = {
  textCenter: {
    textAlign: "center",
  },
}

class Upload extends Component {
  _isMounted = false
  constructor(props) {
    super(props)
    this.state = {
      files: [],
      uploading: false,
      uploadProgress: {},
      successfullUploaded: false,
    }

    this.onFilesAdded = this.onFilesAdded.bind(this)
    this.uploadFiles = this.uploadFiles.bind(this)
    this.sendRequest = this.sendRequest.bind(this)
    this.renderActions = this.renderActions.bind(this)
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.files !== this.props.files) {
      this._isMounted &&
        this.setState({
          files: [...this.props.files],
        })
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  onFilesAdded(files) {
    this.setState((prevState) => ({
      files: prevState.files.concat(files),
    }))
  }

  async uploadFiles() {
    this.setState({ uploadProgress: {}, uploading: true })
    const promises = []
    this.state.files.forEach((file) => {
      promises.push(this.sendRequest(file))
    })
    try {
      await Promise.all(promises)
      this.setState({ successfullUploaded: true, uploading: false })
      this.props.completedCallback && this.props.completedCallback(true)
    } catch (e) {
      // Not Production ready! Do some error handling here instead...
      this.setState({ successfullUploaded: true, uploading: false })
    }
  }

  sendRequest(file) {
    return new Promise((resolve, reject) => {
      const self = this

      let uploadProgress = {
        onUploadProgress: function (event) {
          // console.log(percentCompleted)
          if (event.lengthComputable) {
            const copy = { ...self.state.uploadProgress }
            copy[file.name] = {
              state: "pending",
              percentage: Math.round((event.loaded / event.total) * 100),
            }
            self.setState({ uploadProgress: copy })
          }
        },
      }

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
        ...uploadProgress,
      }

      imageTools.resize(file, { width: 1920, height: 1920 }).then((blob) => {
        // Get signature before uploading the file to Cloudinary
        axios
          .post("/.netlify/functions/cloudinary", {
            folder: getUuid(),
            timestamp: Date.now(),
          })
          .then(function (response) {
            // console.log(response.data)
            const signature = response.data.signature
            const timestamp = response.data.timestamp
            // Upload the file to Cloudinary
            const formData = new FormData()
            formData.append("api_key", cloudinaryApiKey)
            formData.append("file", blob)
            formData.append("folder", getUuid())
            formData.append("signature", signature)
            formData.append("timestamp", timestamp)
            axios
              .post(cloudinaryUrl, formData, config)
              .then(function (response) {
                // console.log(response)
                const copy = { ...self.state.uploadProgress }
                copy[file.name] = { state: "done", percentage: 100 }
                self.props.callback && self.props.callback(response)
                self.setState({ uploadProgress: copy })
                resolve(response)
              })
              .catch(function (error) {
                // console.log(error.response)
                const copy = { ...self.state.uploadProgress }
                copy[file.name] = { state: "error", percentage: 0 }
                self.setState({ uploadProgress: copy })
                reject(error)
              })
          })
          .catch(function (error) {
            // console.log(error.response)
            reject(error)
          })
      })
    })
  }

  /** Working Code with Drupal
   imageTools.resize(file, { width: 1920, height: 1920 }).then(blob => {
        axios
          .post(baseUrl + params, blob, config)
          .then(function(response) {
            // console.log(response)
            const copy = { ...self.state.uploadProgress }
            copy[file.name] = { state: "done", percentage: 100 }
            self.props.callback && self.props.callback(response)
            self.setState({ uploadProgress: copy })
            resolve(response)
          })
          .catch(function(error) {
            console.log(error.response)
            const copy = { ...self.state.uploadProgress }
            copy[file.name] = { state: "error", percentage: 0 }
            self.setState({ uploadProgress: copy })
            reject(error)
          })
      })
   */

  renderProgress(file) {
    const uploadProgress = this.state.uploadProgress[file.name]
    if (this.state.uploading || this.state.successfullUploaded) {
      return (
        <div className="ProgressWrapper">
          <Progress progress={uploadProgress ? uploadProgress.percentage : 0} />
          <img
            className="CheckIcon"
            alt="done"
            src={check}
            style={{
              opacity:
                uploadProgress && uploadProgress.state === "done" ? 1 : 0.1,
            }}
          />
        </div>
      )
    }
  }

  renderActions() {
    const buttonText = this.props.buttonText
    if (this.state.successfullUploaded) {
      return (
        <button
          onClick={() =>
            this.setState({ files: [], successfullUploaded: false })
          }
        >
          Clear
        </button>
      )
    } else {
      return (
        <button
          disabled={
            this.state.files.length === 0 ||
            this.state.uploading ||
            this.props.buttonDisabled
          }
          onClick={this.uploadFiles}
        >
          {buttonText ? buttonText : "Upload"}
        </button>
      )
    }
  }

  render() {
    const { limit, filesText } = this.props
    const { uploading, successfullUploaded, files } = this.state
    return (
      <Grid
        container
        spacing={4}
        direction="column"
        alignItems="stretch"
        // justify="space-evenly"
      >
        <Grid item style={styles.textCenter} xs={12}>
          <Dropzone
            limit={limit}
            onFilesAdded={this.onFilesAdded}
            disabled={
              uploading || successfullUploaded || limit === files.length
            }
            filesText={filesText}
          />
        </Grid>
        <Grid item style={styles.textCenter} xs={12}>
          {files.map((file) => (
            <Grid
              key={file.name}
              item
              xs={12}
              container
              direction="column"
              spacing={0}
              style={{ width: "100%", wordWrap: "break-word" }}
            >
              <Grid item style={styles.textCenter} xs={12}>
                {truncateFilename(file.name, 20)}
              </Grid>
              <Grid item style={styles.textCenter} xs={12}>
                {this.renderProgress(file)}
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid item style={styles.textCenter} xs={12}>
          {this.renderActions()}
        </Grid>
      </Grid>
    )
  }
}

export default Upload
