import React, { useState } from "react"
import { Link, navigate } from "gatsby"
import PropTypes from "prop-types"
import classNames from "classnames"
import { OutboundLink } from "gatsby-plugin-google-analytics"
// core components
import GridContainer from "../Grid/GridContainer.jsx"
import GridItem from "../Grid/GridItem.jsx"
import Footer from "./Footer"
import Button from "../CustomButtons/Button"
import withStyles from "@material-ui/core/styles/withStyles"
import styles from "../../assets/jss/material-kit-pro-react/views/componentsSections/footerStyle.jsx"
import CustomInput from "../CustomInput/CustomInput.jsx"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

function FooterComp(props) {
  const { classes } = props
  const [state, setState] = useState({})

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    fetch("/.netlify/functions/mailchimp", {
      method: "POST",
      body: JSON.stringify({
        email: state.email,
      }),
    })
      .then(response => {
        navigate(form.getAttribute("action"))
      })
      .catch(error =>
        alert(
          "Thank you for your continued interest, but it seems you may already subscribed"
        )
      )
  }
  return (
    <Footer
      theme="white"
      content={
        <div>
          <ul className={classes.socialButtons}>
            <li>
              <OutboundLink href="https://instagram.com/centerconsolesonly">
                <Button
                  justIcon
                  simple
                  // href="https://instagram.com/centerconsolesonly"
                  target="_blank"
                  color="instagram"
                >
                  <FontAwesomeIcon icon={["fab", "instagram"]} />
                </Button>
              </OutboundLink>
            </li>
            <li>
              <OutboundLink href="https://youtube.com/centerconsolesonly">
                <Button justIcon simple target="_blank" color="youtube">
                  <FontAwesomeIcon icon={["fab", "youtube"]} />
                </Button>
              </OutboundLink>
            </li>
            <li>
              <OutboundLink href="https://twitter.com/centerconsolesonly">
                <Button justIcon simple target="_blank" color="twitter">
                  <FontAwesomeIcon icon={["fab", "twitter"]} />
                </Button>
              </OutboundLink>
            </li>
            <li>
              <OutboundLink href="https://facebook.com/centerconsolesonly1">
                <Button justIcon simple target="_blank" color="facebook">
                  <FontAwesomeIcon icon={["fab", "facebook-square"]} />
                </Button>
              </OutboundLink>
            </li>
          </ul>
          <div className={classNames(classes.pullCenter, classes.copyRight)}>
            Copyright &copy; {1900 + new Date().getYear()}{" "}
            <Link to={"/"}>Center Consoles Only, LLC</Link> All Rights Reserved.
          </div>
        </div>
      }
    >
      <div className={classes.footer}>
        <GridContainer>
          <GridItem xs={12} sm={3} md={3}>
            <Link to={"/"}>
              <h5>Center Consoles Only</h5>
            </Link>
            <p>
              At Center Consoles Only, we live and breathe Center Console boats. This page isn't
              for everyone, however, if you love CC's, you came to the right
              place!
            </p>
          </GridItem>
          <GridItem xs={12} sm={2} md={2}>
            <h5>Contact</h5>
            <ul className={classes.linksVertical}>
              <li>
                <Link to={"/contact"}>Contact us</Link>
              </li>
              <li>
                <Link to={"/search"}>Search</Link>
              </li>
            </ul>
          </GridItem>
          <GridItem xs={12} sm={2} md={2}>
            <h5>Legal</h5>
            <ul className={classes.linksVertical}>
              <li>
                <Link to={"/terms-of-use"}>Terms of Use</Link>
              </li>
              <li>
                <Link to={"/privacy"}>Privacy Policy</Link>
              </li>
            </ul>
          </GridItem>
          <GridItem xs={12} sm={5} md={5}>
            <h5>Stay in the Know</h5>
            <p>
              Join our newsletter and get the latest news in your inbox every
              week! We hate spam too, so no worries about that!
            </p>
            <form onSubmit={handleSubmit} action="/thanks">
              <CustomInput
                labelText={"Email Address"}
                id="footeremail"
                formControlProps={{
                  fullWidth: true,
                  required: true,
                }}
                inputProps={{
                  name: "email",
                  onChange: handleChange,
                  placeholder: "Your Email...",
                  type: "email",
                }}
              />
              <Button type="submit" color="primary" style={{ float: "right" }}>
                Join Now
              </Button>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    </Footer>
  )
}

export default withStyles(styles)(FooterComp)

FooterComp.propTypes = {
  theme: PropTypes.oneOf(["dark", "white", "transparent"]),
  big: PropTypes.bool,
}
