import React, { useState } from "react"
import {
  InstantSearch,
  Index,
  Hits,
  connectStateResults,
  connectSearchBox,
} from "react-instantsearch-dom"
import algoliasearch from "algoliasearch/lite"
import * as hitComps from "./hitComps"
import { Input } from "@material-ui/core"
import RegularButton from "../CustomButtons/Button"
import { RefreshContent } from "react-js-pull-to-refresh"

const Results = connectStateResults(
  ({ searchState: state, searchResults: res, children }) =>
    res && res.nbHits > 0 ? children : `No results for '${state.query}'`
)

const Stats = connectStateResults(
  ({ searchResults: res }) =>
    res && res.nbHits > 0 && `${res.nbHits} result${res.nbHits > 1 ? `s` : ``}`
)

const defaultIndices = [
  { name: `Listings`, title: `Listings`, hitComp: `PostHit` },
  { name: `Directory`, title: `Directory`, hitComp: `PostHit` },
  { name: `Blog`, title: `Blog Posts`, hitComp: `PostHit` },
  { name: `Instagram`, title: `Instagram Posts`, hitComp: `PostHit` },
  { name: `Youtube`, title: `Youtube Videos`, hitComp: `PostHit` },
]

function SearchBox({ isSearchStalled, refine }) {
  const style = {
    buttons: {
      float: "right",
    },
  }
  const [query, setQuery] = useState("")
  return (
    <form onSubmit={e => e.preventDefault()} noValidate action="" role="search">
      <Input
        type="search"
        value={query}
        onChange={event => setQuery(event.currentTarget.value)}
        placeholder={"Search CCO"}
        style={{ width: "100%" }}
      />
      <RegularButton style={style.buttons} onClick={() => setQuery("")}>
        Reset query
      </RegularButton>
      <RegularButton style={style.buttons} onClick={() => refine(query)}>
        Submit
      </RegularButton>
      {isSearchStalled && <RefreshContent />}
    </form>
  )
}

const CustomSearchBox = connectSearchBox(SearchBox)

export default function AlgoliaSearch({ indices }) {
  indices = !indices ? defaultIndices : indices
  const [query, setQuery] = useState(``)
  const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY
  )
  return (
    <div>
      <InstantSearch
        searchClient={searchClient}
        indexName={indices[0].name}
        onSearchStateChange={({ query }) => setQuery(query)}
      >
        <CustomSearchBox />
        {query.length > 0 &&
          indices.map(({ name, title, hitComp }) => (
            <Index key={name} indexName={name}>
              <header>
                <h3>{title}</h3>
                <Stats />
              </header>
              <Results>
                <Hits hitComponent={hitComps[hitComp]} />
              </Results>
            </Index>
          ))}
      </InstantSearch>
    </div>
  )
}
