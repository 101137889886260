import React from "react"
import { Highlight, Snippet } from "react-instantsearch-dom"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export const PageHit = (clickHandler) => ({ hit }) => {
  console.log(hit)
  return (
    <div>
      <Link to={hit.slug} onClick={clickHandler}>
        <h4>
          <Highlight attribute="title" hit={hit} tagName="mark" />
        </h4>
      </Link>
      <Highlight attribute="summary" hit={hit} tagName="mark" />
    </div>
  )
}
export const PostHit = ({ hit }) => {
  console.log(hit)
  return (
    <div>
      <Link to={hit.alias}>
        <h4>
          <Highlight attribute="title" hit={hit} tagName="mark" />
        </h4>
      </Link>
      <Snippet attribute="title" hit={hit} tagName="mark" />
      <Highlight attribute="summary" hit={hit} tagName="mark" />
      <div>
        <FontAwesomeIcon icon={"calendar"} />
        &nbsp;
        <Highlight attribute="created" hit={hit} tagName="mark" />
        &emsp;
      </div>
    </div>
  )
}
/*
<FontAwesomeIcon icon={"tags"} />
			&nbsp;
{hit.tags.map((tag, index) => (
				<Fragment key={tag}>
					{index > 0 && `, `}
					{tag}
				</Fragment>
			))}
 */
