import React, { Component } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
//@material-ui Core
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import Tooltip from "@material-ui/core/Tooltip"
//Material Kit Pro
import withStyles from "@material-ui/core/styles/withStyles"
import navbarsStyle from "../../assets/jss/material-kit-pro-react/views/componentsSections/navbarsStyle"
import Header from "./Header"
import Button from "../CustomButtons/Button"
import logo from "../../images/logo.svg"
// CCO Core Items
import Search from "../Search"
import Post from "../Post"
// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import CustomDropdown from "../CustomDropdown/CustomDropdown"

const style = {
  desktopLogin: {
    textDecoration: "none",
  },
  mobileLogin: {
    paddingTop: "20px",
    textDecoration: "none",
  },
  linkMatch: {
    height: "50px",
    color: "#3C4858",
  },
  bag: {
    borderRadius: "0 !important",
    "&:hover": {
      backgroundColor: "transparent !important",
      boxShadow: "none !important",
    },
  },
  bagSuperscript: {
    position: "relative",
    top: "-10px",
    color: "red",
  },
  dropdownLinks: {
    textDecoration: "none",
    color: "inherit",
    display: "block",
  },
}

const totalQuantity = (lineItems) => {
  let total = 0
  lineItems.forEach((el) => {
    total = total + el.quantity
  })
  return total
}

class HeaderComp extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const {
      classes,
      isMobile,
      isAuthenticated,
      globalHistory,
      color,
      storeContext,
    } = this.props
    // const { title } = useSiteMetadata();
    return (
      <Header
        transparent
        brand={logo}
        fixed
        announcement={false}
        color={color}
        changeColorOnScroll={{
          height: 50,
          color: "white",
        }}
        storeContext={storeContext}
        links={
          <List className={classes.list + " " + classes.mlAuto}>
            {!isMobile && (
              <>
                <ListItem className={classes.listItem}>
                  <Search size={"1x"} />
                </ListItem>
                <ListItem
                  style={style.desktopLogin}
                  className={classes.listItem}
                >
                  {isAuthenticated ? (
                    <Post text={true} />
                  ) : (
                    <Link
                      to={"/login"}
                      state={{ redirect: globalHistory.location.pathname }}
                    >
                      <Tooltip
                        id="tooltip-tina"
                        title="Please log in to post"
                        placement="top"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <>
                          <Button
                            id={"buttonTest"}
                            color="transparent"
                            className={classes.navLink + " HeaderButton"}
                            style={style.linkMatch}
                          >
                            New Post
                          </Button>
                        </>
                      </Tooltip>
                    </Link>
                  )}
                </ListItem>
              </>
            )}
            <ListItem className={classes.listItem}>
              <CustomDropdown
                buttonText="Listings"
                dropdownHeader="I want to..."
                buttonProps={{
                  className: classes.navLink + " HeaderButton",
                  color: "transparent",
                  style: style.linkMatch,
                }}
                dropdownList={[
                  <Link style={style.dropdownLinks} to={"/listings"}>
                    Search
                  </Link>,
                  { divider: true },
                  <Link style={style.dropdownLinks} to={"/u/post/boat"}>
                    Create New
                  </Link>,
                ]}
              />
            </ListItem>
            <ListItem className={classes.listItem}>
              <CustomDropdown
                buttonText="Directory"
                dropdownHeader="I want to..."
                buttonProps={{
                  className: classes.navLink + " HeaderButton",
                  color: "transparent",
                  style: style.linkMatch,
                }}
                dropdownList={[
                  <Link style={style.dropdownLinks} to={"/directory"}>
                    Search
                  </Link>,
                  { divider: true },
                  <Link style={style.dropdownLinks} to={"/u/dashboard"}>
                    Create New
                  </Link>,
                ]}
              />
            </ListItem>
            <ListItem className={classes.listItem}>
              <Link to={"/financing"}>
                <Button
                  color="transparent"
                  className={classes.navLink + " HeaderButton"}
                  style={style.linkMatch}
                >
                  Financing
                </Button>
              </Link>
            </ListItem>
            <ListItem className={classes.listItem}>
              <Link to={"/blog"}>
                <Button
                  color="transparent"
                  className={classes.navLink + " HeaderButton"}
                  style={style.linkMatch}
                >
                  Blog
                </Button>
              </Link>
            </ListItem>
            <ListItem className={classes.listItem}>
              <a href="https://openfisherman.com/collections/vendors?q=Center%20Consoles%20Only" target="_blank">
                {/*<Link to={"/shop"}>*/}
                <Button
                  color="transparent"
                  className={classes.navLink + " HeaderButton"}
                  style={style.linkMatch}
                >
                  Shop
                </Button>
                {/*</Link>*/}
              </a>
            </ListItem>
            {storeContext.checkout.lineItems.length > 0 && !isMobile && (
              <ListItem
                style={isAuthenticated ? {} : { paddingRight: "10px" }}
                className={classes.listItem}
              >
                <Button
                  color="transparent"
                  className={classes.navLink + " HeaderButton"}
                  style={style.linkMatch}
                  onClick={(e) => {
                    e.preventDefault()
                    storeContext.isCartOpen
                      ? storeContext.handleCartClose()
                      : storeContext.handleCartOpen()
                  }}
                >
                  <FontAwesomeIcon
                    id={isMobile ? "SearchIconMobile" : "SearchIcon"}
                    icon={["fal", "shopping-bag"]}
                    style={{ marginTop: "-15px" }}
                    size={"2x"}
                  />
                  <p style={style.bagSuperscript}>
                    {totalQuantity(storeContext.checkout.lineItems)}
                  </p>
                </Button>
              </ListItem>
            )}
            {isMobile && (
              <ListItem className={classes.listItem}>
                <Link to={"/contact"}>
                  <Button
                    color="transparent"
                    className={classes.navLink + " HeaderButton"}
                    style={style.linkMatch}
                  >
                    Contact Us
                  </Button>
                </Link>
              </ListItem>
            )}
            {isAuthenticated && (
              <ListItem
                className={classes.listItem}
                style={isMobile ? style.mobileLogin : style.desktopLogin}
              >
                <Link to={"/u/dashboard"}>
                  <Button
                    color="transparent"
                    className={classes.navLink + " HeaderButton"}
                    style={style.linkMatch}
                  >
                    Dashboard
                  </Button>
                </Link>
              </ListItem>
            )}
            <ListItem
              className={classes.listItem}
              style={isMobile ? style.mobileLogin : style.desktopLogin}
            >
              <Link
                to={isAuthenticated ? "/logout" : "/login"}
                state={{
                  redirect:
                    globalHistory.location.pathname !== undefined
                      ? globalHistory.location.pathname
                      : "/u/dashboard",
                }}
              >
                <Button color="primary" className={classes.registerNavLink}>
                  {isAuthenticated ? "Logout" : "Login | Register"}
                </Button>
              </Link>
            </ListItem>
          </List>
        }
      />
    )
  }
}

HeaderComp.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark",
  ]),
  links: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // this.props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // this.props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark",
    ]).isRequired,
  }),
}

// export default withStyles(navbarsStyle)(HeaderComp)
export default withStyles(navbarsStyle)(HeaderComp)
