import React from "react"
import { globalHistory, useLocation } from "@reach/router"
import PropTypes from "prop-types"
import { withStoreContext } from "../context/StoreContext"
import Cart from "../components/Shop/Cart"
import { isAuthenticated } from "../utils/Auth"
import {
  PullToRefresh,
  ReleaseContent,
  RefreshContent,
  PullDownContent,
} from "react-js-pull-to-refresh"
// Main CSS
// import "./layout.css"
// @material-ui Core Components
import Header from "../components/Header"
import Footer from "../components/Footer"
import FooterNative from "../components/FooterNative"
import { Container } from "@material-ui/core"
//Font Awesome React
import { library } from "@fortawesome/fontawesome-svg-core/"
// Font Awesome Brand Icons
import { faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter"
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons/faFacebookSquare"
import { faYoutube } from "@fortawesome/free-brands-svg-icons/faYoutube"
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram"
import { faAlgolia } from "@fortawesome/free-brands-svg-icons/faAlgolia"
// Font Awesome Pro Light Icons
import { faShip } from "@fortawesome/pro-light-svg-icons/faShip"
import { faUsers } from "@fortawesome/pro-light-svg-icons/faUsers"
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus"
import { faSearch } from "@fortawesome/pro-light-svg-icons/faSearch"
import { faHouseFlood } from "@fortawesome/pro-light-svg-icons/faHouseFlood"
import { faHome } from "@fortawesome/pro-light-svg-icons/faHome"
import { faArrowAltDown } from "@fortawesome/pro-light-svg-icons/faArrowAltDown"
import { faArrowAltUp } from "@fortawesome/pro-light-svg-icons/faArrowAltUp"
import { faShoppingBag } from "@fortawesome/pro-light-svg-icons/faShoppingBag"
// Font Awesome Pro Solid Icons
import { faTimes } from "@fortawesome/pro-solid-svg-icons/faTimes"
import { faUser } from "@fortawesome/pro-solid-svg-icons/faUser"
import { faLock } from "@fortawesome/pro-solid-svg-icons/faLock"
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons/faEnvelope"
import { faSync } from "@fortawesome/pro-solid-svg-icons/faSync"
import { faPlusCircle } from "@fortawesome/pro-solid-svg-icons/faPlusCircle"
import { faCalendar } from "@fortawesome/pro-solid-svg-icons/faCalendar"
import { faTags } from "@fortawesome/pro-solid-svg-icons/faTags"
import { faSpinner } from "@fortawesome/pro-solid-svg-icons/faSpinner"
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons/faArrowLeft"
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons/faChevronLeft"
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons/faChevronRight"
import { faCheck } from "@fortawesome/pro-solid-svg-icons/faCheck"
import { faEdit } from "@fortawesome/pro-solid-svg-icons/faEdit"
import { faCalculator } from "@fortawesome/pro-solid-svg-icons/faCalculator"
import { faMinus } from "@fortawesome/pro-solid-svg-icons/faMinus"
// Font Awesome Pro Regular Icons
// import { far } from "@fortawesome/pro-regular-svg-icons"
// Media Query
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery"

// Add to library to make available throughout project
library.add(
  faEnvelope,
  faLock,
  faUser,
  faTimes,
  faTwitter,
  faFacebookSquare,
  faYoutube,
  faInstagram,
  faShip,
  faUsers,
  faPlus,
  faSearch,
  faHouseFlood,
  faHome,
  faSync,
  faPlusCircle,
  faCalendar,
  faTags,
  faAlgolia,
  faArrowAltDown,
  faArrowAltUp,
  faSpinner,
  faArrowLeft,
  faChevronLeft,
  faChevronRight,
  faCheck,
  faEdit,
  faCalculator,
  faShoppingBag,
  faMinus
)

const styles = {
  containerMarginTop: {
    marginTop: "72px",
    paddingLeft: "0px !important",
  },
  mainBottomPadding: {
    marginBottom: "72px",
  },
}

function Layout({ children, ...props }) {
  const isMobile = useMediaQuery("(max-width:768px)")
  const onRefresh = () => {
    return new Promise((resolve) => {
      console.log("refreshed")
      window.location.reload()
      setTimeout(resolve, 2000)
    })
  }

  const AppBody = () => {
    const {
      disableGutters = false,
      maxWidth = "lg",
      headerColor = "white",
      storeContext,
    } = props
    return 1 === 1 ? (
      // return storeContext.checkout.id !== undefined ? (
      <>
        <Header
          globalHistory={globalHistory}
          isAuthenticated={isAuthenticated()}
          isMobile={isMobile}
          color={headerColor}
          storeContext={storeContext}
        />
        <Container
          disableGutters={disableGutters}
          maxWidth={maxWidth}
          spacing={0}
          id={"main"}
          style={styles.containerMarginTop}
        >
          <main style={isMobile ? styles.mainBottomPadding : null}>
            {children}
            <Cart
              checkout={storeContext.checkout}
              isCartOpen={storeContext.isCartOpen}
              handleCartClose={storeContext.handleCartClose}
              updateQuantityInCart={storeContext.updateQuantityInCart}
              removeLineItemInCart={storeContext.removeLineItemInCart}
            />
          </main>
        </Container>
        {isMobile ? (
          <FooterNative
            globalHistory={globalHistory}
            isAuthenticated={isAuthenticated()}
          />
        ) : (
          <Footer />
        )}
      </>
    ) : (
      <RefreshContent height={"100vh"} />
    )
  }

  return isMobile ? (
    <PullToRefresh
      pullDownContent={<PullDownContent />}
      releaseContent={<ReleaseContent />}
      refreshContent={<RefreshContent />}
      pullDownThreshold={200}
      onRefresh={onRefresh}
      triggerHeight={100}
      backgroundColor="white"
    >
      <AppBody />
    </PullToRefresh>
  ) : (
    <AppBody />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  disableGutters: PropTypes.bool,
  maxWidth: PropTypes.string,
  headerColor: PropTypes.string,
}

export default withStoreContext(Layout)
