import React from "react"
import Slider from "react-slick"
// core components
import GridContainer from "../../components/Grid/GridContainer.jsx"
import GridItem from "../../components/Grid/GridItem.jsx"
import carouselStyle from "../../assets/jss/material-kit-pro-react/views/componentsSections/carouselStyle.jsx"
import withStyles from "@material-ui/core/styles/withStyles"
import PropTypes from "prop-types"

const classes = {
  ...carouselStyle,
  ...withStyles,
}

export default function CarouselCard(props) {
  const { images, customSettings } = props
  let settings = {
    adaptiveHeight: true,
    arrows: false,
    dots: true,
    draggable: true,
    infinite: true,
    lazyLoad: "progressive",
    pauseOnDotsHover: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 1000,
    // beforeChange: (current, next) => currentVid = current,
  }

  if (customSettings) {
    settings = customSettings
  }

  return (
    <div style={classes.section} id="carousel">
      <div style={classes.marginAuto}>
        <GridContainer alignItems="center">
          <GridItem xs={12} sm={12} md={12} style={classes.marginAuto}>
            <Slider {...settings}>{images}</Slider>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}

CarouselCard.propTypes = {
  // images: PropTypes.any.isRequired,
  images: PropTypes.array.isRequired,
}
