import React from "react"
import axios from "axios"
import { Link, navigate } from "gatsby"
import arrayMove from "array-move"
import Upload from "../Upload"
import Modal from "./modal"
import { getAuthHeadersJsonapi } from "../../utils/Auth"
import { truncateString } from "../../helpers/common"
// Material UI & Kit
import { Input } from "@material-ui/core"
import Clearfix from "../Clearfix/Clearfix"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import SortableImages from "../SortableImages"

const baseUrl = `${process.env.GATSBY_API_URL}`
const params = "/jsonapi/node/community"
const charLimit = 500
const autoCloseTimeout = 5000

const style = {
  button: {
    fontSize: "14px",
    display: "inline-block",
    height: "36px",
    minWidth: "88px",
    padding: "6px 16px",
    lineHeight: "1.42857143",
    textAlign: "center",
    verticalAlign: "middle",
    cursor: "pointer",
    border: "0",
    borderRadius: "2px",
    background: "rgb(0, 172, 193)",
    color: "#fff",
    outline: "0",
  },
}

class Post extends React.Component {
  _isMounted = false
  constructor(props) {
    super(props)
    this.state = {
      message: "",
      images: [],
      open: false,
      success: false,
      uploadComplete: false,
    }
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  handleModal = c => {
    this._isMounted && this.setState({ open: c })
  }

  handleInputChange = e => {
    this._isMounted &&
      this.setState({
        message: e.target.value,
      })
  }

  uploadCallback = r => {
    if (this._isMounted && r.data.public_id !== undefined) {
      const publicId = r.data.public_id
      const type = r.data.resource_type
      this.setState({
        images: [...this.state.images, { pid: publicId, resourceType: type }],
      })
    }
  }

  uploadCompleteCallback = () => {
    const { images } = this.state
    images.length === 1
      ? this.submitPost()
      : this._isMounted && this.setState({ uploadComplete: true })
    // Add error handling
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this._isMounted &&
      this.setState(({ images }) => ({
        images: arrayMove(images, oldIndex, newIndex),
      }))
  }

  submitPost = () => {
    let self = this
    const { message, images } = self.state

    if (images.length > 0) {
      const payload = {
        data: {
          type: "node--community",
          attributes: {
            entity_type: "node",
            title: `${truncateString(message, 50)}`,
            body: {
              value: message,
              format: "plain_text",
              summary: `${truncateString(message, 50)}`,
            },
            field_cloudinary: JSON.stringify(images),
          },
        },
      }

      axios
        .post(baseUrl + params, payload, getAuthHeadersJsonapi())
        .then(function(response) {
          // console.log(response)
          self._isMounted &&
            self.setState(
              { images: [], success: true, message: "" },
              self.props.latestFeed
            )
        })
        .catch(function(error) {
          console.log(error.response)
        })
    }
  }

  renderForm = () => {
    const { message, uploadComplete, images } = this.state
    return (
      <>
        {!uploadComplete && (
          <>
            <div style={{ float: "right" }}>
              {message.length >= 8 && (
                <FontAwesomeIcon
                  color={"green"}
                  icon={["fas", "check"]}
                  size={"2x"}
                />
              )}
            </div>
            <div
              onClick={e => {
                e.preventDefault()
                this.setState({ open: false })
              }}
              style={{ textAlign: "center", color: "#00acc1", paddingBottom: "10px" }}
            >
              <Link to={"/u/post/boat"}>
                Selling a boat? Click here to Post it Now!
              </Link>
            </div>
            <Input
              type="text"
              value={message}
              onChange={this.handleInputChange}
              placeholder={
                "Some great story, build or Center Console related thoughts here... No ADS Please ;)"
              }
              style={{ width: "100%" }}
              required={true}
              inputProps={{ maxLength: charLimit }}
              multiline={true}
              rows={5}
            />
            <div style={{ float: "right" }}>
              <small>
                8 character min. {message.length} of {charLimit}
              </small>
            </div>
            <Clearfix />
            <div style={{ marginTop: "30px", textAlign: "center" }}>
              <Upload
                limit={10}
                callback={this.uploadCallback}
                completedCallback={this.uploadCompleteCallback}
                buttonText={"Submit"}
                filesText={"Image"}
                buttonDisabled={message.length < 8}
              />
              <br />
              <small>
                If you upload more than one item you will be able to rearrange
                them in the next step
              </small>
            </div>
          </>
        )}
        {uploadComplete && images.length > 1 && (
          <div style={{ textAlign: "center" }}>
            <h3>Set Media Order</h3>
            <h5>Drag and drop into the desired order then submit your post!</h5>
            <SortableImages
              images={images}
              onSortEnd={this.onSortEnd}
              axis="xy"
            />
            <button
              onClick={e => {
                e.preventDefault()
                this.submitPost()
              }}
              style={style.button}
            >
              Submit Post
            </button>
          </div>
        )}
      </>
    )
  }

  renderStatusMessage = () => {
    setTimeout(() => {
      this._isMounted && this.setState({ open: false, success: false })
      navigate("/")
    }, autoCloseTimeout)
    return (
      <>
        <h3>Congratulations on your successful post! </h3>
        <small style={{ float: "right" }}>
          Window will close on its own in {autoCloseTimeout / 1000} seconds
        </small>
      </>
    )
  }

  render() {
    const { open, success } = this.state
    const { size, color, text } = this.props
    return (
      <Modal
        size={size}
        color={color}
        text={text}
        open={open}
        handleModal={this.handleModal}
        data={success ? this.renderStatusMessage() : this.renderForm()}
      />
    )
  }
}

export default Post
