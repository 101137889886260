// sort function for '00.00, 00.01, 01.00, etc
export function sortCommentsByMultiLevelThread(d, order) {
	let sort = {
			asc: function (a, b) {
				let l = 0, m = Math.min(a.value.length, b.value.length);
				while (l < m && a.value[l] === b.value[l]) {
					l++;
				}
				return l === m ? a.value.length - b.value.length : a.value[l] - b.value[l];
			},
			desc: function (a, b) {
				return sort.asc(b, a);
			}
		},

		// temporary array holds objects with position and sort-value
		mapped = d.map(function (el, i) {
			return { index: i, value: el.attributes.thread.replace('/','').split('.').map(Number) };
		});

	// sorting the mapped array containing the reduced values
	mapped.sort(sort[order] || sort.asc);

	// container for the resulting order
	return mapped.map(function (el) {
		return d[el.index];
	});
}

export function indentCommentStyling(thread) {
	let string = thread,
		searchFor = '.',
		level = 0,
		pos = string.indexOf(searchFor);

	while (pos > -1) {
		++level;
		pos = string.indexOf(searchFor, ++pos);
	}
	return level * 40;
}