import * as Config from "./config"
import axios from "axios"
import { navigate } from "gatsby"

export const isBrowser = typeof window !== "undefined"
let tokenRenewalTimeout

const getUser = item => {
  if (window.localStorage.ccoUser) {
    const user = JSON.parse(window.localStorage.ccoUser)
    return item ? user[item] : user
  }
  return {}
}

const setUser = user => (window.localStorage.ccoUser = JSON.stringify(user))

const setSession = authResult => {
  if (!isBrowser) {
    return
  }

  const expiresAt = authResult.expires_in + ((Date.now() / 1000) | 0)
  setUser({
    access_token: authResult.access_token,
    refresh_token: authResult.refresh_token,
    expires_in: authResult.expires_in,
    expires_at: expiresAt,
    uid: authResult.user_id,
    uuid: authResult.uuid,
    email: authResult.email,
  })
  scheduleRenewal()
}

export function userLogin(username, password, callback) {
  const data = new FormData()
  data.append("grant_type", "password")
  data.append("client_id", getClientId())
  data.append("username", username)
  data.append("password", password)
  axios
    .post(getEndpoint("token"), data)
    .then(function(response) {
      setSession(response.data)
      callback(null, response)
      // console.log(response)
    })
    .catch(function(error) {
      // console.log(error)
      let errorResponse = error.response.data.message
      errorResponse = errorResponse.replace(/(?:\r\n|\r|\n)/g, "<br />")
      // console.log(errorResponse)
      callback(errorResponse, null)
    })
}

export const logout = () => {
  if (isBrowser) {
    clearTimeout(tokenRenewalTimeout)
    setUser({})
  }
  navigate("/")
}

export function renewToken() {
  if (!isBrowser) return

  const refreshToken = getRefreshToken()
  if (refreshToken !== undefined) {
    // Attempt to get new Auth token with Refresh Token if Auth token is expiring
    const data = new FormData()
    data.append("grant_type", "refresh_token")
    data.append("client_id", getClientId())
    data.append("refresh_token", refreshToken)

    axios
      .post(getEndpoint("token"), data)
      .then(function(response) {
        setSession(response.data)
        // console.log(response.data)
      })
      .catch(function(error) {
        console.log(error.response)
        logout()
        return false
      })
  }
}

function scheduleRenewal() {
  if (isBrowser) {
    // const timeout = (parseInt(getExpiresIn(), 10) - 3000) * 1000
    const timeout = 22000 * 1000
    clearTimeout(tokenRenewalTimeout)
    tokenRenewalTimeout = setTimeout(renewToken, timeout)
  }
}

export function getUserInfo() {
  if (!isAuthenticated()) {
    return
  }

  const uid = getUid()

  return axios.get(
    getEndpoint("user") + uid + "?_format=json",
    getAuthHeaders()
  )
}

export const isAuthenticated = () => {
  if (!isBrowser) return false

  const currentTime = (Date.now() / 1000) | 0
  return currentTime < getExpiresAt()
}

export const getEndpoint = endpoint => {
  if (!isBrowser) {
    return ""
  }

  return Config.endpoints[endpoint]
}

export const getClientId = () => {
  if (!isBrowser) {
    return ""
  }

  return Config.client_id
}

export const getAccessToken = () => {
  if (!isBrowser) {
    return ""
  }
  // For SSR, we’re never authenticated.
  return getUser("access_token")
}

export const getRefreshToken = () => {
  if (!isBrowser) {
    return ""
  }

  return getUser("refresh_token")
}

export const getUid = () => {
  if (!isBrowser) {
    return ""
  }
  return getUser("uid")
}

export const getUuid = () => {
  if (!isBrowser) {
    return ""
  }
  return getUser("uuid")
}

export const getEmail = () => {
  if (!isBrowser) {
    return ""
  }
  return getUser("email")
}

export const getExpiresIn = () => {
  if (!isBrowser) {
    return ""
  }

  return getUser("expires_in")
}

export const getExpiresAt = () => {
  if (!isBrowser) {
    return ""
  }

  return getUser("expires_at")
}

export const getAuthHeaders = () => {
  if (!isBrowser) {
    return ""
  }

  return {
    headers: { Authorization: "Bearer " + getAccessToken() },
  }
}

export const getAuthHeadersJsonapi = () => {
  if (!isBrowser) {
    return ""
  }

  return {
    headers: {
      Authorization: "Bearer " + getAccessToken(),
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  }
}

export const getAuthHeadersJsonapiFile = (filename, callback) => {
  if (!isBrowser) {
    return ""
  }

  return {
    headers: {
      Authorization: "Bearer " + getAccessToken(),
      Accept: "application/vnd.api+json",
      "Content-Type": "application/octet-stream",
      "Content-Disposition": `file; filename="${filename}"`,
    },
  }
}

export const getAuthHeadersApi = () => {
  if (!isBrowser) {
    return ""
  }

  return {
    headers: {
      Authorization: "Bearer " + getAccessToken(),
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }
}
