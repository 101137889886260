import React from "react"
import { Link } from "gatsby"
// nodejs library that concatenates classes
import classNames from "classnames"
// nodejs library to set properties for components
import PropTypes from "prop-types"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import Button from "@material-ui/core/Button"
import Hidden from "@material-ui/core/Hidden"
import Drawer from "@material-ui/core/Drawer"
// @material-ui/icons
import Menu from "@material-ui/icons/Menu"
import Close from "@material-ui/icons/Close"
// core components
import headerStyle from "../../assets/jss/material-kit-pro-react/components/headerStyle.jsx"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import GridContainer from "../Grid/GridContainer"
import GridItem from "../Grid/GridItem"

const style = {
  bag: {
    paddingTop: "12px",
    paddingLeft: "32px",
    color: "inherit",
  },
  bagSuperscript: {
    position: "relative",
    top: "-10px",
    right: "-4px",
    color: "red",
  },
  announcement: {
    width: "100%",
    backgroundColor: "#00acc1",
    textAlign: "center",
    color: "white",
    padding: ".625rem",
    // marginTop: "74px"
  },
}

const announcementText = (
  <Link style={{ color: "white" }} to={"/seavee"}>
    <strong>
      CLICK HERE TO RSVP TO THE SEAVEE 450z UNVEILING NOW!
    </strong>
  </Link>
)

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mobileOpen: false,
    }
    this.handleDrawerToggle = this.handleDrawerToggle.bind(this)
    this.headerColorChange = this.headerColorChange.bind(this)
  }
  handleDrawerToggle() {
    this.setState({ mobileOpen: !this.state.mobileOpen })
  }
  componentDidMount() {
    const { color } = this.props
    if (this.props.changeColorOnScroll) {
      window.addEventListener("scroll", this.headerColorChange, {
        passive: true,
      })
      if (color === "transparent") {
        document.getElementById("SearchIcon").style.color = "white"
        const x = document.getElementsByClassName("HeaderButton")
        for (let e of x) {
          e.style.color = "white"
        }
      }
    }
  }
  headerColorChange() {
    const { classes, color, changeColorOnScroll } = this.props
    const windowsScrollTop = window.pageYOffset
    const b = document.body.getElementsByTagName("header")[0]
    if (windowsScrollTop > changeColorOnScroll.height) {
      b.classList.remove(classes[color])
      b.classList.add(classes[changeColorOnScroll.color])
      const s = document.getElementById("SearchIcon")
      if (s) s.style.color = "#3C4858"
      const x = document.getElementsByClassName("HeaderButton")
      for (let e of x) {
        e.style.color = "#3C4858"
      }
    } else {
      if (color === "transparent") {
        const s = document.getElementById("SearchIcon")
        if (s) s.style.color = "white"
        const x = document.getElementsByClassName("HeaderButton")
        for (let e of x) {
          e.style.color = "white"
        }
      }
      b.classList.add(classes[color])
      b.classList.remove(classes[changeColorOnScroll.color])
    }
  }
  componentWillUnmount() {
    if (this.props.changeColorOnScroll) {
      window.removeEventListener("scroll", this.headerColorChange)
    }
  }

  totalQuantity = (lineItems) => {
    let total = 0
    lineItems.forEach((el) => {
      total = total + el.quantity
    })
    return total
  }

  cart = () => {
    const { storeContext } = this.props
    return (
      <>
        {storeContext.checkout.lineItems.length > 0 && (
          <Button
            id="MobileBag"
            style={style.bag}
            onClick={(e) => {
              e.preventDefault()
              storeContext.isCartOpen
                ? storeContext.handleCartClose()
                : storeContext.handleCartOpen()
            }}
          >
            <FontAwesomeIcon
              icon={["fal", "shopping-bag"]}
              style={{ color: "inherit", marginTop: "-15px" }}
              size={"2x"}
            />
            <p style={style.bagSuperscript}>
              {this.totalQuantity(storeContext.checkout.lineItems)}
            </p>
          </Button>
        )}
      </>
    )
  }

  render() {
    const {
      classes,
      color,
      links,
      brand,
      fixed,
      absolute,
      announcement,
      storeContext,
      bioLink = false,
    } = this.props
    const appBarClasses = classNames({
      [classes.appBar]: true,
      [classes[color]]: color,
      [classes.absolute]: absolute,
      [classes.fixed]: fixed,
    })
    return (
      <div>
        <AppBar
          style={{ paddingTop: announcement && 0 }}
          className={appBarClasses}
        >
          <GridContainer
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ margin: 0, width: "100%" }}
          >
            {announcement && (
              <GridItem
                xs={12}
                style={{
                  paddingLeft: 0,
                  paddingRight: 0,
                }}
              >
                <div style={style.announcement}>{announcementText}</div>
              </GridItem>
            )}
            <GridItem xs={12}>
              <Toolbar className={classes.container}>
                <Link to="/">
                  {
                    <img
                      src={brand}
                      className={classes.img}
                      style={{ maxHeight: "38px" }}
                      alt="Center Consoles Only"
                    />
                  }
                </Link>
                <Hidden smDown implementation="css" className={classes.hidden}>
                  <div className={classes.collapse}>{links}</div>
                </Hidden>
                <Hidden mdUp>
                  {storeContext && this.cart()}
                  {!bioLink ? (
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={this.handleDrawerToggle}
                    >
                      <Menu />
                    </IconButton>
                  ) : (
                    <Link to={"/"}>
                      <Button
                        variant={"outlined"}
                        size={"large"}
                        style={{ marginTop: "8px", paddingBottom: "30px" }}
                      >
                        Visit Website
                      </Button>
                    </Link>
                  )}
                </Hidden>
              </Toolbar>
              <Hidden mdUp implementation="css">
                <Drawer
                  variant="temporary"
                  anchor={"right"}
                  open={this.state.mobileOpen}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  onClose={this.handleDrawerToggle}
                >
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={this.handleDrawerToggle}
                    className={classes.closeButtonDrawer}
                  >
                    <Close />
                  </IconButton>
                  <div className={classes.appResponsive}>{links}</div>
                </Drawer>
              </Hidden>
            </GridItem>
          </GridContainer>
        </AppBar>
      </div>
    )
  }
}

Header.defaultProp = {
  color: "white",
  announcement: false,
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark",
  ]),
  links: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  announcement: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // this.props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // this.props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark",
    ]).isRequired,
  }),
}

export default withStyles(headerStyle)(Header)
