import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import axios from "axios"
import ReactPlayer from "react-player"
import ProgressiveImage from "../ProgressiveImage"
import handleViewport from "react-in-viewport"
import CarouselCard from "../CarouselCard"

const cloudinaryBase = `https://res.cloudinary.com/${process.env.GATSBY_CLOUDINARY_CLOUD_NAME}`

const cartoonify = "e_blur:500,q_1,f_auto"
// const cartoonify = "e_blur:1000,q_1,f_auto/e_cartoonify"

const style = {
  fullWidth: {
    maxWidth: "100%",
    width: "100%",
    height: "auto",
  },
  playerWrapper: {
    position: "relative",
    paddingTop: "56.25%" /* Player ratio: 100 / (1280 / 720) */,
  },
  reactPlayer: {
    position: "absolute",
    top: 0,
    left: 0,
  },
}

/*
 * Handles Playing or Pausing video when in frame
 */
const Video = (props) => {
  const { edge, transformations, inViewport, forwardedRef } = props
  return (
    <ReactPlayer
      ref={forwardedRef}
      playing={inViewport}
      poster={`${cloudinaryBase}/video/upload/${edge.pid}.jpg`}
      url={`${cloudinaryBase}/video/upload/${transformations}/${edge.pid}`}
      className="slick-image"
      width={"100%"}
      height={"auto"}
      style={style.reactPlayer}
      controls
      autoPlay
      playsInline
      muted
    />
  )
}

const ViewportBlock = handleViewport(Video)

export const CloudinaryMedia = (props) => {
  const { imageArray, transformations, progressive } = props
  const pTransformation =
    progressive != null ? `${progressive},${cartoonify}` : ""
  let images = []

  if (imageArray[0]) {
    images = imageArray.map((edge, index) => (
      <React.Fragment key={index}>
        {edge.resourceType.indexOf("image") !== -1 ? (
          <ProgressiveImage
            style={style.fullWidth}
            alt={edge.pid}
            overlaySrc={`${cloudinaryBase}/image/upload/${pTransformation}/${edge.pid}`}
            src={`${cloudinaryBase}/image/upload/${transformations}/${edge.pid}`}
          />
        ) : (
          <div style={style.playerWrapper}>
            <ViewportBlock transformations={transformations} edge={edge} />
          </div>
        )}
      </React.Fragment>
    ))
  }
  return images
}

export const CloudinaryMediaCarousel = (props) => {
  const { imageArray, transformations, progressive } = props
  const pTransformation =
    progressive != null ? `${progressive},${cartoonify}` : ""

  if (imageArray[0]) {
    return (
      <CarouselCard
        style={{ width: "100%", height: "auto" }}
        images={imageArray.map((edge, index) => {
          return (
            <React.Fragment key={index}>
              {edge.resourceType.indexOf("image") !== -1 ? (
                <ProgressiveImage
                  style={style.fullWidth}
                  alt={edge.pid}
                  overlaySrc={`${cloudinaryBase}/image/upload/${pTransformation}/${edge.pid}`}
                  src={`${cloudinaryBase}/image/upload/${transformations}/${edge.pid}`}
                />
              ) : (
                <div style={style.playerWrapper}>
                  <ViewportBlock
                    transformations={transformations}
                    edge={edge}
                  />
                </div>
              )}
            </React.Fragment>
          )
        })}
      />
    )
  }
}

export const CloudinaryMediaSingle = (props) => {
  const { image, transformations, alt, styles, progressive } = props
  const pTransformation = progressive ? `${progressive},${cartoonify}` : ""
  return (
    <div>
      {image.resourceType.indexOf("image") !== -1 ? (
        <ProgressiveImage
          // className={"cover"}
          style={styles != null ? styles : style.fullWidth}
          alt={alt ? alt : image.pid}
          overlaySrc={`${cloudinaryBase}/image/upload/${pTransformation}/${image.pid}`}
          src={`${cloudinaryBase}/image/upload/${transformations}/${image.pid}`}
        />
      ) : (
        <img
          src={`${cloudinaryBase}/video/upload/w_90,l_play/${image.pid}.jpg`}
          alt={alt ? alt : image.pid}
          style={styles !== undefined ? styles : style.fullWidth}
        />
      )}
    </div>
  )
}

export function CloudinaryMediaSigned(props) {
  const { image, transformations, alt, styles } = props
  const [signedImage, setSignedImage] = useState(
    `${cloudinaryBase}/image/upload/defaults/listing_placeholder`
  )
  const toSign = `${transformations}/${image.pid}`

  useEffect(() => {
    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        await axios
          .post("/.netlify/functions/cloudinarySignedMedia", toSign, {
            cancelToken: source.token,
          })
          .then(function (response) {
            const sig = response.data.signature
            setSignedImage(
              `${cloudinaryBase}/image/upload/${sig}/${transformations}/${image.pid}`
            )
          })
      } catch (error) {
        if (axios.isCancel(error)) {
          //cancelled
        } else {
          throw error
        }
      }
    }
    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  return (
    <div>
      {image.resourceType.indexOf("image") !== -1 ? (
        <img
          src={signedImage}
          alt={alt ? alt : image.pid}
          style={styles != null ? styles : style.fullWidth}
        />
      ) : (
        <img
          src={`${cloudinaryBase}/video/upload/w_90,l_play/${image.pid}.jpg`}
          alt={alt ? alt : image.pid}
          style={styles != null ? styles : style.fullWidth}
        />
      )}
    </div>
  )
}

CloudinaryMedia.propTypes = {
  imageArray: PropTypes.array.isRequired,
  transformations: PropTypes.string,
  progressive: PropTypes.string,
}

CloudinaryMediaCarousel.propTypes = {
  imageArray: PropTypes.array.isRequired,
  transformations: PropTypes.string,
  progressive: PropTypes.string,
}

CloudinaryMediaSingle.propTypes = {
  image: PropTypes.object.isRequired,
  transformations: PropTypes.string,
  alt: PropTypes.string,
  styles: PropTypes.object,
  progressive: PropTypes.string,
}

CloudinaryMediaSigned.propTypes = {
  image: PropTypes.object.isRequired,
  transformations: PropTypes.string,
  alt: PropTypes.string,
  styles: PropTypes.object,
}
