/**
 *
 * Configuration for Auth
 */
export const url = `${process.env.GATSBY_API_URL}`

export const client_id = `${process.env.GATSBY_API_CLIENT_ID}`

export const endpoints = []
endpoints["user"] = url + "/user/"
endpoints["token"] = url + "/oauth/token"
endpoints["csrf"] = url + "/rest/session/token"
endpoints["registration"] = url + "/user/register"
