import React from "react"
import AlgoliaSearch from "./algoliaSearch"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
// @material-ui/icons
import Close from "@material-ui/icons/Close"
// core components
import Button from "../CustomButtons/Button"
// Styling
import modalStyle from "../../assets/jss/material-kit-pro-react/modalStyle"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import useTheme from "@material-ui/core/styles/useTheme"
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery"
import IconButton from "@material-ui/core/IconButton"

const style = theme => ({
  ...modalStyle(theme),
  searchButton: {
    borderRadius: "0 !important",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
})

const useStyles = makeStyles(style)

export default function Search({ color, size, indices }) {
  const [scrollingModal, setScrollingModal] = React.useState(false)
  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const isMobile = useMediaQuery("(max-width:768px)")

  return (
    <div>
      <IconButton
        aria-label="Search"
        onClick={() => setScrollingModal(true)}
        className={classes.searchButton}
      >
        <FontAwesomeIcon
          id={isMobile ? "SearchIconMobile" : "SearchIcon"}
          color={color}
          size={size}
          icon={["fal", "search"]}
          style={{ margin: "-8px -8px -8px 34px" }}
        />
      </IconButton>
      {scrollingModal && (
        <Dialog
          fullScreen={fullScreen}
          fullWidth={true}
          maxWidth={"sm"}
          open={scrollingModal}
          onClose={() => setScrollingModal(false)}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <Button
              simple
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              onClick={() => setScrollingModal(false)}
            >
              {" "}
              <Close className={classes.modalClose} />
            </Button>
            <h4 className={classes.modalTitle}>Search CCO</h4>
          </DialogTitle>
          <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
          >
            <AlgoliaSearch indices={indices} />
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <Button onClick={() => setScrollingModal(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  )
}
