import React from "react"
import PropTypes from "prop-types"
import { SortableContainer, SortableElement } from "react-sortable-hoc"
import GridContainer from "../../components/Grid/GridContainer"
import GridItem from "../../components/Grid/GridItem"
import { CloudinaryMediaSingle } from "../CloudinaryMedia"
import Button from "../CustomButtons/Button"

const style = {
  container: {
    margin: "30px 10px",
    width: "100%",
    textAlign: "center",
  },
  sortableHeader: {
    zIndex: "1000",
  },
}

const SortableItem = SortableElement(({ value, children }) => (
  <GridItem xs={4} lg={3} style={style.sortableHeader}>
    <CloudinaryMediaSingle
      image={value}
      transformations={"w_225,h_225,c_fill_pad,g_auto"}
      alt={value.pid}
    />
    {children}
  </GridItem>
))

export const SortableImages = SortableContainer(({ images, onRemove }) => {
  return (
    <GridContainer style={style.container} spacing={2} alignItems={"center"}>
      {images.map((value, index) => (
        <SortableItem
          key={index}
          index={index}
          idx={index}
          value={value}
          onRemove={onRemove}
        >
          <div style={{ padding: "4px" }} />
          {onRemove && (
            <Button
              style={{ zIndex: "3000" }}
              color={"danger"}
              onClick={(e) => {
                e.preventDefault()
                onRemove(index)
              }}
            >
              Remove Item
            </Button>
          )}
        </SortableItem>
      ))}
    </GridContainer>
  )
})

export default SortableImages

SortableImages.propTypes = {
  images: PropTypes.array.isRequired,
}
