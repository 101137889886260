import React, { Component } from "react"
import LineItem from "./LineItem"

class Cart extends Component {
  constructor(props) {
    super(props)

    this.openCheckout = this.openCheckout.bind(this)
  }

  openCheckout() {
    // Open new window
    // window.open(this.props.checkout.webUrl)

    // Open in same window
    window.location = this.props.checkout.webUrl
  }

  render() {
    // console.log(this.props)
    let line_items = this.props.checkout.lineItems.map((line_item) => {
      return (
        <LineItem
          updateQuantityInCart={this.props.updateQuantityInCart}
          removeLineItemInCart={this.props.removeLineItemInCart}
          key={line_item.id.toString()}
          line_item={line_item}
        />
      )
    })
    let subtotalPrice =
      typeof this.props.checkout.subtotalPrice != "undefined"
        ? parseFloat(this.props.checkout.subtotalPrice.amount).toFixed(2)
        : "0"
    let totalPrice =
      typeof this.props.checkout.totalPrice != "undefined"
        ? parseFloat(this.props.checkout.totalPrice.amount).toFixed(2)
        : "0"
    return (
      <div className={`Cart ${this.props.isCartOpen ? "Cart--open" : ""}`}>
        <header className="Cart__header">
          <h2>Your cart</h2>
          <button
            onClick={(e) => {
              e.preventDefault()
              this.props.handleCartClose()
            }}
            className="Cart__close"
          >
            ×
          </button>
        </header>
        <ul className="Cart__line-items">{line_items}</ul>
        <footer className="Cart__footer">
          <div className="Cart-info clearfix">
            <div className="Cart-info__total Cart-info__small">Subtotal</div>
            <div className="Cart-info__pricing">
              <span className="pricing">$ {subtotalPrice}</span>
            </div>
          </div>
          {/*<div className="Cart-info clearfix">*/}
          {/*  <div className="Cart-info__total Cart-info__small">Taxes</div>*/}
          {/*  <div className="Cart-info__pricing">*/}
          {/*    <span className="pricing">$ {this.props.checkout.totalTax}</span>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className="Cart-info clearfix">
            <div className="Cart-info__total Cart-info__small">Total</div>
            <div className="Cart-info__pricing">
              <span className="pricing">$ {totalPrice}</span>
            </div>
          </div>
          <button className="Cart__checkout button" onClick={this.openCheckout}>
            Checkout
          </button>
        </footer>
      </div>
    )
  }
}

export default Cart
