import React from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import useMediaQuery from "@material-ui/core/useMediaQuery"
// @material-ui/icons
import Close from "@material-ui/icons/Close"
// core components
import Button from "@material-ui/core/Button"
// Styling
import modalStyle from "../../assets/jss/material-kit-pro-react/modalStyle"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import useTheme from "@material-ui/core/styles/useTheme"
// import * as Auth from "../../utils/Auth"

const style = theme => ({
  ...modalStyle(theme),
  modalRootLocal: {
    "& > div:first-child": {
      display: "none",
    },
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  navLink: {
    color: "inherit",
    margin: "0px",
    display: "inline-flex",
    position: "relative",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "20px",
    textTransform: "uppercase",
    textDecoration: "none",
    backgroundColor: "transparent",
    fontFamily: "Roboto",
  },
})

const useStyles = makeStyles(style)

// export default function Search({ data, color, size, text, open, handleModal }) {
const Modal = props => {
  const {
    data,
    color,
    size,
    text,
    open,
    handleModal,
    icon,
  } = props
  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <div>
      <div color="transparent" onClick={() => handleModal(true)}>
        {text ? (
          <button className={classes.navLink}>NEW POST</button>
        ) : icon ? (
          <Button>
            <FontAwesomeIcon color="#00acc1" size={size} icon={icon} />
          </Button>
        ) : (
          <FontAwesomeIcon color={color} size={size} icon={["fal", "plus"]} />
        )}
      </div>
      {open && (
        <Dialog
          fullScreen={fullScreen}
          fullWidth={true}
          maxWidth={"sm"}
          open={open}
          onClose={() => handleModal(false)}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <Button
              simple={"true"}
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              onClick={() => handleModal(false)}
            >
              {" "}
              <Close className={classes.modalClose} />
            </Button>
            <h4 className={classes.modalTitle}>Post To Center Consoles Only</h4>
          </DialogTitle>
          <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
          >
            {data}
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            {/*<Button onClick={() => handleModal(false)}>Close</Button>*/}
          </DialogActions>
        </Dialog>
      )}
    </div>
  )
}

export default Modal
