import React, { Component } from "react"
import { Link } from "gatsby"
import AppBar from "@material-ui/core/AppBar"
import Search from "../Search"
import Post from "../Post"
// Material UI Core
import CssBaseline from "@material-ui/core/CssBaseline"
import Toolbar from "@material-ui/core/Toolbar"
import Fab from "@material-ui/core/Fab"
import IconButton from "@material-ui/core/IconButton"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const styles = {
  appBar: {
    top: "auto",
    bottom: 0,
    backgroundColor: "#5987a1",
    height: "70px",
  },
  toolbar: {
    minHeight: "65px",
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: "0 auto",
    backgroundColor: "#0599b9",
    color: "white",
  },
  modalButtonDiv: {
    marginTop: "6px",
    transform: "scale(1.25)",
  },
}

class BottomAppBar extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { isAuthenticated, globalHistory } = this.props

    return (
      <React.Fragment>
        <CssBaseline />
        <AppBar position="fixed" style={styles.appBar}>
          <Toolbar style={styles.toolbar}>
            <Link to={"/"}>
              <IconButton aria-label="Home">
                <FontAwesomeIcon
                  color={"white"}
                  icon={["fal", "home"]}
                  style={{ margin: "-8px -8px -8px -24px" }}
                />
              </IconButton>
            </Link>
            <Link to={"/listings"}>
              <IconButton aria-label="Boats and Listings">
                <FontAwesomeIcon
                  color={"white"}
                  icon={["fal", "ship"]}
                  style={{ margin: "-8px -8px -8px -64px" }}
                />
              </IconButton>
            </Link>
            <Fab aria-label="Add listing or post" style={styles.fabButton}>
              <div style={styles.modalButtonDiv}>
                {isAuthenticated ? (
                  <Post color={"white"} size={"2x"} />
                ) : (
                  <Link
                    to={"/login"}
                    state={{ redirect: globalHistory.location.pathname }}
                    aria-label="New Post"
                  >
                    <FontAwesomeIcon
                      color={"white"}
                      icon={["fal", "plus"]}
                      size={"2x"}
                    />
                  </Link>
                )}
              </div>
            </Fab>
            <div style={styles.grow} />
            <Search color={"white"} size={"1x"} />
            <Link to={"/u/messages"}>
              <IconButton aria-label="Messaging">
                <FontAwesomeIcon
                  color={"white"}
                  icon={["fal", "users"]}
                  style={{ margin: "-8px" }}
                />
              </IconButton>
            </Link>
          </Toolbar>
        </AppBar>
      </React.Fragment>
    )
  }
}

export default BottomAppBar
